<template>
  <div v-if="getPaste" ref="container">
    <div class="d-flex justify-space-between">
      <div class="buttons">
        <v-btn @click="copyLink" small>Copy link</v-btn>
        <v-btn :href="downloadUri" :download="getPaste.fileName" small>Download</v-btn>
        <v-btn @click="deletePaste" color="error" small>Delete</v-btn>
      </div>
      <div class="d-flex align-center buttons">
        <div class="d-flex">
          <v-icon>mdi-account</v-icon>
          <div>
            <div v-if="getPaste.createdByUser.userName">
              {{ getPaste.createdByUser.userName }}
            </div>
            <div v-else>
              Guest
            </div>
          </div>
        </div>
        <div>
          <v-icon>mdi-calendar</v-icon>
          {{ getDate }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-center" style="margin-top: 4rem">
      <component
          :is="componentType"
          :code="this.code"
          :paste="getPaste"
          :maxWidth="maxWidth"
      />
    </div>

  </div>
</template>

<script>

import { URL_API } from '@/lib/constants'

export default {
  name: 'PasteDetail',
  props: {
    code: String,
    paste: Object,
  },
  components: {
    ImageDetail: () => import('@/views/Paste/ImageDetail'),
    VideoDetail: () => import('@/views/Paste/VideoDetail'),
    FileDetail: () => import('@/views/Paste/FileDetail'),
  },
  data: () => ({
    loadedPaste: null,
    maxWidth: null,
  }),
  mounted() {
    if (!this.paste) {
      this.$store.state.ky.get(`paste/${this.code}`).json().then(res => {
        this.loadedPaste = res
      }).finally(() => {
        this.maxWidth = this.$refs.container.getBoundingClientRect().width / 3
      })
    } else
      this.maxWidth = this.$refs.container.getBoundingClientRect().width / 3
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(window.location.href)
    },
    deletePaste() {
      this.$store.state.ky.delete(`paste/${this.code}`).then(() => {
        this.$router.replace({
          name: 'Pastes',
        })
      })
    },
  },
  computed: {
    componentType() {
      switch (this.getPaste.type) {
        case 'image':
          return 'ImageDetail'
        case 'video':
          return 'VideoDetail'
        case 'file':
          return 'FileDetail'
        default:
          return ''
      }
    },
    getPaste() {
      return this.paste ?? this.loadedPaste
    },
    downloadUri() {
      return `${URL_API}/paste/${this.code}/raw`
    },
    getDate() {
      const d = new Date(this.getPaste.createdAt)
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
      return `${da}. ${mo} ${ye}`
    },
  },
}
</script>

<style scoped>

.buttons button, a, div {
  margin-right: 1rem;
}

</style>